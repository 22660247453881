function goGainIntegral() {
    const u: string = navigator.userAgent
    console.log(navigator.userAgent,'navigator.userAgent');
    const isAndroid: boolean = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1 //android安卓
    const isiOS: boolean = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) //ios苹果
    if (u.indexOf("eshebao") != -1) {
        if (isAndroid) {
            ;(window as any).eshebao.gotoMyIntegral()
        } else if (isiOS) {
            ;(window as any).webkit.messageHandlers.eshebao.postMessage("gotoMyIntegral")
        }
    }
}

export { goGainIntegral }