























import { Component, Vue, Watch } from "vue-property-decorator"
import { goGainIntegral } from '@/utils/common'

@Component({
    name:'Default'
})
export default class Default extends Vue {
    isModelShowLack: boolean = false
    created() {
        this.VueFunc.$on("integralLack",(data: any) => {
            this.isModelShowLack = data[0]
        })
    }

    goIntegral() {
		this.isModelShowLack = false
		goGainIntegral()
	}
}
