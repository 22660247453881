








import { Component, Vue, Watch, Prop } from "vue-property-decorator"
import VueFunc from "@/utils/VueFunc"

@Component({
	name: "Default"
})
export default class Default extends Vue {
	balloonsContent = ""
	showBalloons = false

	// @Watch("balloonsContent", { immediate: true, deep: true })
	// onBalloonsContentChanged(newVal: string, oldVal: string) {
	// 	// console.log(newVal,oldVal)
	// }

	created() {
		VueFunc.$on("balloons", (data: any)=> {
			// console.log(data)
			this.balloonsContent = data[0]
			this.showBalloons = data[1]
		})
	}

	mounted() {}
}
