import Vue from "vue"
import VueRouter, { RouteConfig } from "vue-router"

Vue.use(VueRouter)

// const originalPush = VueRouter.prototype.push
// VueRouter.prototype.push = function push(location: any) {
// 	return originalPush.call(this, location).catch((err: any) => err)
// }

const routes: Array<RouteConfig> = [
	{
		path: "/",
		component: () => import("../views/main/index.vue"),
		children: [
			{
				path: "/",
				name: "Home",
				component: () => import("../views/home/index.vue")
			},
			{
				path: "/my",
				name: "My",
				component: () => import("../views/my/index.vue")
			}
		]
	},
	{
		path: "/my/orderRecord",
		name: "orderRecord",
		component: () => import("../views/my/OrderRecord.vue")
	},
	{
		path: "/goodsClassifyList",
		name: "goodsClassifyList",
		component: () => import("../views/home/goodsClassifyList.vue")
	},
	{
		path: "/goodsDetails/:id",
		name: "goodsDetails",
		component: () => import("../views/productsExchange/goodsDetails.vue")
	},
	{
		path: "/submitOrder",
		name: "submitOrder",
		component: () => import("../views/productsExchange/submitOrder.vue")
	},
	{
		path: "/my/useDirection",
		name: "useDirection",
		component: () => import("../views/my/useDirection.vue")
	},
  {
		path: "/my/addressList",
		name: "addressList",
		component: () => import("../views/my/addressList.vue")
	},
	{
		path: "/my/receiverAddress",
		name: "receiverAddress",
		component: () => import("../views/my/receiverAddress.vue")
	},
	{
		path: "/InvitingUsers",
		name: "receiverAddress",
		component: () => import("../views/InvitingUsers/index.vue")
	},
	{
		path: "/InvitingUsersRules",
		name: "receiverAddressRules",
		component: () => import("../views/InvitingUsers/rules.vue")
	},
	{
		path: "/InvitingXiaoyRules",
		name: "receiverXiaoyRules",
		component: () => import("../views/InvitingUsers/rule.vue")
	},
  {
    path: "/my/logisticsInfo",
    name:"logisticsInfo",
    component: () => import("../views/my/logisticsInfo.vue")
  }
]

const router = new VueRouter({
	mode: "hash",
	base: process.env.BASE_URL,
	routes
})

export default router
