/*
 * @Description: 
 */
/*
 * @Description: 
 */
import axios from "axios"
import { getToken } from "@/utils/cookies"

const Request = axios.create({
	baseURL: process.env.BASE_URL,
	timeout: 30000
})
// 请求拦截
Request.interceptors.request.use(
	async config => {
		if (getToken()) {
			const accessToken = await getToken()
			config.headers.common["Authorization"] = `bearer ${accessToken}`
		}
		if (window.location.href.indexOf("localhost") > -1) {
			config.headers.common[
				"Authorization"
			] = `bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoidTE3MzA3ODIwNjMiLCJodHRwOi8vc2NoZW1hcy5taWNyb3NvZnQuY29tL3dzLzIwMDgvMDYvaWRlbnRpdHkvY2xhaW1zL3JvbGUiOiJQZXJzb25hbFVzZXIiLCJVc2VySUQiOiJkZTNiYzI1OC0xYzdjLTRiZjgtOWMyZS02ZWYzOTAzNGEwZTUiLCJQaG9uZU51bWJlciI6IjE3Mzg2MDgyMDYzIiwiSGVhZEltYWdlIjoiIiwiTmlja05hbWUiOiLmmJPku5QyMDYzIiwiTG9naW5UeXBlIjoiNiIsIlNpZ25JZCI6IjYzMjI1MWE2LTk1MjItNDRmZS1hZDcxLWJmZTU2OTRkYzBhNCIsIm5iZiI6MTcyMzE4NjcxOCwiZXhwIjoxNzIzMjA4MzE4LCJpc3MiOiJmemFwaXFhLmVzaGViYW8uY24iLCJhdWQiOiJBbGwifQ.zgUTXf3lNA0fZTh9E0nT5XENix3pfHtT7Xo49ZYMYXs`
		}
		return config
	},
	err => {
		return Promise.reject(err)
	}
)

// 响应拦截
Request.interceptors.response.use(
	response => {
		// console.log(response)

		return response.data
	},
	err => {
		if (err.response.status === 401) {
			const u: string = navigator.userAgent
			const isAndroid: boolean = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1 //android安卓
			const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) //ios苹果
			if (u.indexOf("eshebao") != -1) {
				if (isAndroid) {
					(window as any).eshebao.gotoLogin()
				} else if (isiOS) {
					(window as any).webkit.messageHandlers.eshebao.postMessage("gotoLogin")
				}
			}
		}
		return Promise.reject(err)
	}
)

export default Request
// GET,
// POST,
