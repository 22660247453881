import request from "../utils/request"

const api = {
	// 积分商品列表
	goodsList(params: any) {
		return request({
			url: `${process.env.VUE_APP_BaseUrl}/api/v1.0/goods/list`,
			method: "GET",
			params
		})
	},
	// 专区类型
	specialzone(params: any) {
		return request({
			url: `${process.env.VUE_APP_BaseUrl}/api/v1.0/specialzone/list`,
			method: "GET",
			params
		})
	},
	// 首页轮播图
	GetAdvertByDisplayPage(params: any) {
		return request({
			url: `${process.env.VUE_APP_BaseUrl2}/api/adverts/GetAdvertByDisplayPage`,
			method: "GET",
			params
		})
	},
	// 专区类型
	goodsDetails(params: any) {
		return request({
			url: `${process.env.VUE_APP_BaseUrl}/api/v1.0/goods/${params}`,
			method: "GET"
		})
	},
	// 获取用户积分
	CreditScores() {
		return request({
			url: `${process.env.VUE_APP_BaseUrl3}/api/v1.0/Credit/CreditScores `,
			method: "GET"
		})
	},
    // 获取用户信息
	GetUserByIdNew() {
		return request({
			url: `${process.env.VUE_APP_BaseUrl2}/api/User/GetUserByIdNew`,
			method: "GET",
		})
	},
    // 获取最近收货人
	lastestAddress() {
		return request({
			url: `${process.env.VUE_APP_BaseUrl}/api/v1.0/receiver/lastest`,
			method: "GET",
		})
	},
    // 新增修改收货人
	receiverAddress(data:any) {
		return request({
			url: `${process.env.VUE_APP_BaseUrl}/api/v1.0/receiver`,
			method: "POST",
            data
		})
	},
    // 检查库存
	checkstock(data:any) {
		return request({
			url: `${process.env.VUE_APP_BaseUrl}/api/v1.0/goods/checkstock/${data.id}/${data.count}`,
			method: "GET",
            data
		})
	},
	// 提交订单
	submitOrder(data:any) {
		return request({
			url: `${process.env.VUE_APP_BaseUrl}/api/v1.0/order/submit`,
			method: "POST",
            data
		})
	},
	// 订单列表
	orderList(params:any) {
		return request({
			url: `${process.env.VUE_APP_BaseUrl}/api/v1.0/order/list`,
			method: "GET",
            params
		})
	},
	// 绑定推荐人
	BindRecommend(data:any) {
		return request({
			url: `${process.env.VUE_APP_BaseUrl3}/api/v1.0/Recommend/BindRecommend`,
			method: "POST",
            data
		})
	},
	// 获取限时促销列表
	flashsalelist() {
		return request({
			url: `${process.env.VUE_APP_BaseUrl}/api/v1.0/goods/flashsalelist?Count=6`,
			method: "get"
		})
	},

  // 获取积分+金额商品详情
  fuagoodsDetails(id:any) {
		return request({
			url: `${process.env.VUE_APP_BaseUrl}/api/v1.0/fuagoods/${id}`,
			method: "get"
		})
	},
  // 检查积分+金额商品库存,并返回防重令牌
  fuagoodsCheckstock(data:any) {
		return request({
			url: `${process.env.VUE_APP_BaseUrl}/api/v1.0/fuagoods/checkstock/${data.id}/${data.count}`,
			method: "GET"
		})
	},
  // 获取所有收货人
  getAllReceiver() {
		return request({
			url: `${process.env.VUE_APP_BaseUrl}/api/v1.0/receiver/GetAllReceiver`,
			method: "GET"
		})
	},
  // 获取城市
  receiverRegion() {
		return request({
			url: `${process.env.VUE_APP_BaseUrl}/api/v1.0/receiver/region`,
			method: "GET"
		})
	},
  // 新增/修改收货人
  receiverAdd(data:any) {
		return request({
			url: `${process.env.VUE_APP_BaseUrl}/api/v1.0/receiver`,
			method: "POST",
      data
		})
	},
  // 删除收货人
  receiverDel(params:any) {
    return request({
      url: `${process.env.VUE_APP_BaseUrl}/api/v1.0/receiver/${params.id}`,
      method: "delete",
    })
  },
  // 获取用户下单选择的收货人地址,没查到选默认地址
  receiverSelect() {
		return request({
			url: `${process.env.VUE_APP_BaseUrl}/api/v1.0/receiver/select`,
			method: "GET"
		})
	},
  // 设置用户下单选择的收货人地址
  receiverSet(data:any) {
		return request({
			url: `${process.env.VUE_APP_BaseUrl}/api/v1.0/receiver/set`,
			method: "POST",
      data
		})
	},
  // 用户提交订单
  fuaorderSubmit(data:any) {
		return request({
			url: `${process.env.VUE_APP_BaseUrl}/api/v1.0/fuaorder/submit`,
			method: "POST",
      data
		})
	},
  // 通过大订单id查询订单列表详情
  getOrderDetailUI(params:any) {
		return request({
			url: `${process.env.VUE_APP_BaseUrl}/api/v1.0/fuaorder/GetOrderDetailUI/${params}`,
			method: "GET"
		})
	},
  // 通过大订单id查询订单详情
  getOrderDetail(params:any) {
		return request({
			url: `${process.env.VUE_APP_BaseUrl}/api/v1.0/fuaorder/GetOrderDetail/${params}`,
			method: "GET"
		})
	},
  // 用户修改订单地址
  fuaorderUpdateAddress(data:any) {
		return request({
			url: `${process.env.VUE_APP_BaseUrl}/api/v1.0/fuaorder/UpdateAddress`,
			method: "POST",
      data
		})
	},

}

export default api
