import api from "../api/index"
import VueFunc from "@/utils/VueFunc"


const protoInstall = {
    install: (Vue:any) => {
        Vue.prototype.$api = api
        Vue.prototype.VueFunc = VueFunc
    }
}

export default protoInstall;