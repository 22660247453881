import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import "./assets/scss/style.scss"
import protoInstall from "./utils/install"
import VConsole from "vconsole"
// import  VueFunc  from '@/utils/VueFunc';
import InfiniteLoading from "vue-infinite-loading"

//registerHooks.js
import Component from "vue-class-component"

// px转rem
import './utils/lib-flexible'
// vant ui样式
import 'vant/lib/index.css';

const vConsole: any = process.env.NODE_ENV !== "production" ? new VConsole() : ""

Vue.config.productionTip = false

Component.registerHooks(["beforeRouteEnter", "beforeRouteLeave", "beforeRouteUpdate"])
// Vue.use(VueFunc)
Vue.use(vConsole)
Vue.use(protoInstall)
Vue.use(InfiniteLoading)

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount("#app")
